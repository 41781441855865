.techtacticals-lets-talk {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.techtacticals-lets-talk-page {
  width: inherit;
  height: inherit;
  display: flex;
}

.techtacticals-side-video {
  width: 40%;
  height: 100%;
  background-color: #38C8AE;
}

.techtacticals-side-video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.techtacticals-lets-talk-form-container {
  width: 100%;
  height: 100%;
  padding: 6vh 0px 10vh 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.techtacticals-lets-talk-title {
  font-size: 3.5vw;
  font-weight: 800;
  letter-spacing: 1px;
  font-family: "Montserrat";

  color: #040816;
  margin-bottom: 2.8vh;
}

.techtacticals-lets-talk-description {
  font-size: 1.1vw;
  letter-spacing: 0.2px;
  color: #040816;

  margin-bottom: 6vh;
}

.techtacticals-lets-talk-form {
  width: 60%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.techtacticals-lets-talk-form label {
  width: 100%;
}

.techtacticals-lets-talk-form label p {
  letter-spacing: 0.2px;
  color: #040816;
  font-size: 1.2vw;
  font-weight: 600;

  margin-bottom: 10px;
}

.techtacticals-lets-talk-form label textarea,
.techtacticals-lets-talk-form label input {
  width: 100%;

  outline: none;
  border-radius: 8px;
  border: 1px solid #9AA5B1;

  font-size: 1.05vw !important;
  font-weight: 500;
  font-family: "Nunito";
}

.techtacticals-lets-talk-form label textarea {
  height: 18vh;
  padding: 14px;
  margin-bottom: 3vh;

  resize: none;
}

.techtacticals-lets-talk-form label input {
  margin-bottom: 3.5vh;
  padding: 12px;
}

.techtacticals-lets-talk-submit-button {
  width: 80%;
  margin-top: 3.5vh;

  font-size: 1vw;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media screen and (max-width: 20cm) {
  .techtacticals-lets-talk-page {
    position: relative;
  }

  .techtacticals-side-video {
    width: 100%;
  }

  .techtacticals-lets-talk-form-container {
    padding: 4vh 0px 10vh 0px;
  }

  .techtacticals-lets-talk-form-container {
    position: absolute;
    background-color: #04081691;
  }

  .techtacticals-lets-talk-title {
    color: white;
    font-size: 7.5vw;
  }

  .techtacticals-lets-talk-form {
    width: 85%;
  }

  .techtacticals-lets-talk-description {
    font-size: 4.1vw;
    width: 80%;
    margin-bottom: 3vh;
    text-align: center;
    color: white;
  }

  .techtacticals-lets-talk-form label textarea,
  .techtacticals-lets-talk-form label input {
    font-size: 4.05vw !important;
    background-color: #000000d6;
    color: white;
  }
  .techtacticals-side-video{
    background-color: transparent;
  }
  .techtacticals-lets-talk-form label p {
    color: white;
    font-size: 4.2vw;
  }

  .techtacticals-lets-talk-form label textarea {
    height: 15vh;
    margin-bottom: 2vh;
  }

  .techtacticals-lets-talk-form label input {
    margin-bottom: 2vh;
  }
  .techtacticals-lets-talk-submit-button{
    padding: 12px 12px;
    width: 100%;
    font-size: 2vh;
  }
}