.techtacticals-faq {
  width: 100vw;
  height: 100vh;
  color: black;
  background-color: white;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.techtacticals-faq .heading {
  font-size: 3.8vw;
  font-weight: 600;
  font-family: "Montserrat";

  margin-bottom: 60px;

  position: absolute;
  top: 8%;
}

.accordions-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: -60px;
}

.accordion-row {
  width: 70%;
  padding: 5px 10px;
  border-radius: 4px;
}

.accordion-expand, .accordion-summary {
  width: 100%;
}

.accordion-expand {
  display: flex;
  justify-content: space-between;
  padding-right: 10px;

  font-size: 1.6vw;
  font-weight: 600;

  cursor: pointer;
}

.accordion-row {
  padding-bottom: 10px;
  margin-bottom: 50px;
  border-bottom: 1px solid #9AA5B1;
  overflow: hidden;
  border-radius: 0;
}

.accordion-detail {
  font-size: 1.1vw;
  font-weight: 400;
  letter-spacing: 0.6px;

  padding-top: 10px;
}

.techtacticals-faq button {
  position: absolute;
  bottom: 80px;

  font-size: 1.2vw;
}

.accordion-expand .rotate-arrow {
  width: 12px;
  transform: rotate(90deg);
}

@media screen and (max-width: 20cm) {
  .techtacticals-faq {
    height: 93vh;
  }

  .techtacticals-faq .heading {
    margin-bottom: 0px;
    font-size: 6.8vw;
    font-weight: 700;
    margin: 0px 30px;
    text-align: center;
  }

  .accordion-expand {
    font-size: 4vw;
  }

  .accordion-expand > p {
    padding-right: 20px;
    font-size: 4vw;
  }

  .accordion-row {
    width: 90%;
    margin-bottom: 40px;
  }

  .accordion-detail {
    font-size: 3.4vw;
  }

  .techtacticals-faq button {
    font-size: 3.8vw;
  }
}