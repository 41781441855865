.why-choose-techtacticals {
  height: 100vh;
  width: 100vw;
  padding: 116px 40px 116px 114px;
  background-color: white;
}

.why-choose-techtacticals-content {
  height: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.why-choose-techtacticals-content > img {
  min-width: 44vw;
  min-height: 80vh;
  height: inherit;
  border-radius: 30px;
  margin-right: 60px;
}

.why-choose-techtacticals-content > .content-box {
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.why-choose-techtacticals-content > .content-box .title {
  font-size: 1.7vw;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.why-choose-techtacticals-content > .content-box .heading {
  font-size: 3.3vw;
  font-weight: bold;
  font-family: "Montserrat";

  margin-bottom: 54px;
}

.why-choose-techtacticals-content > .content-box .description {
  font-size: 1.4vw;
  letter-spacing: 0.27px;
  line-height: 4vh;

  margin-bottom: 60px;
}

.why-choose-techtacticals-content > .content-box > button {
  font-size: 1.1vw;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.why-choose-techtacticals-content > .content-box > button > img {
  width: 0.7vw;
  margin-left: 10px;
}

.techtacticals-button-text {
  color: #38C8AE;
  background-color: transparent;

  outline: none;
  border: none;
}

@media screen and (max-width: 20cm) {
  .why-choose-techtacticals {
    height: 100%;
  }

  .why-choose-techtacticals {
    padding: 0px 0px 0px 0px;
  }

  .why-choose-techtacticals-content {
    flex-direction: column-reverse;
  }

  .why-choose-techtacticals-content > img {
    min-width: unset;
    min-height: unset;
    height: unset;
    width: 100%;
    margin: 0 auto;
    border-radius: unset;
    scale: 1.1 1;
  }

  .why-choose-techtacticals-content > .content-box {
    padding: 20px 30px;
  }

  .why-choose-techtacticals-content > .content-box .title {
    font-size: 3.2vw;
    font-weight: 700;
  }

  .why-choose-techtacticals-content > .content-box .heading {
    font-size: 6.8vw;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .why-choose-techtacticals-content > .content-box .description {
    line-height: unset;
    font-size: 3.5vw;
    letter-spacing: 0px;
    margin-bottom: 20px;
  }

  .why-choose-techtacticals-content > .content-box > button {
    font-size: 5vw;
  }

  .why-choose-techtacticals-content > .content-box > button > img {
    width: 3vw;
  }
}