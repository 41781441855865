.techtacticals-landing-page {
  width: 100vw;
  height: 89vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}

.techtacticals-app .techtacticals-droplet-animation {
  width: 14%;
}
.techtacticals-button-filled{
  display: none;
}
.techtacticals-background-fill {
  width: 130vmax;
  height: 130vmax;

  border-radius: 50%;
  animation: expand-background 2s cubic-bezier(0.075, 0.82, 0.165, 1) 800ms forwards;

  position: absolute;
  z-index: -1;
}

.techtacticals-background-fill.no-radius {
  border-radius: 0%;
}

@keyframes expand-background {
  from {
    width: 0vmax;
    height: 0vmax;

    background-image: url("/src/Assets/images/TechTacticalsBg.png");
    background-repeat: no-repeat;
    background-position: center;
  } to {
    width: 130vmax;
    height: 130vmax;

    background-image: url("/src/Assets/images/TechTacticalsBg.png");
    background-repeat: no-repeat;
    background-position: center;
  }
}


.landing-page-content {
  width: 100%;
  height: 100%;

  position: relative;

  animation: fade-in;
  animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  animation-duration: 600ms;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(300px);
  } to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.techtacticals-video {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.62), transparent);
}

.techtacticals-video video {
  width: 100%;
  margin-top: -30%;
}

.video-overlay {
  height: inherit;

  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background-color: rgba(0, 0, 0, 0.363);
}

.video-overlay-content {
  height: 50%;
  padding: 60px 0px 0px 80px;

  position: absolute;
  top: 20%;
  left: 0px;
  right: 0px;
  bottom: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.text-highlight {
  color: #38C8AE;
}

.video-overlay-content > p {
  font-size: 4.5vw;
  font-weight: 600;
  font-family: "Montserrat";

  color: white;
  white-space: nowrap;
}

/* .video-overlay-content > p > .second-line {
  margin-left: 23%;
} */
.video-overlay-content > p > .sub-head{
  font-size: 20px;
  display: flex;
  line-height: 34px;
  margin-top: 15px;
}

.techtacticals-description {
  width: 35%;
  padding: 10px 0px 10px 80px;
  margin-right: 180px;

  color: white;
  border-left: 2px solid #B1B1B1;

  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.8px;
}

.techtacticals-description > p {
  padding: 20px 20px;

  border-radius: 10px;
  background: rgba( 255, 255, 255, 0.03 );
  backdrop-filter: blur( 20px );

  font-size: 1.6vw;
}

.gallery-view-container {
  width: 42vw;
  height: 40vh;

  position: absolute;
  left: 50%;
  bottom: 80px;
  z-index: 999;
  transform: translate(-50%, -12%);

  border-radius: 25px;
}

.gallery-view-container .swiper-slide {
  overflow: hidden;
}

.swiper-pagination {
  bottom: -22%;
}

@media screen and (min-width: 38cm) {
  .gallery-view-container {
    bottom: 10%;
  }
  .techtacticals-button-filled{
    display: initial;
  }
  .gallery-view-container .swiper-container .swiper-pagination {
    bottom: -1%;

    align-items: center;
  }

  .gallery-view-container .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0px 14px;
  }

  .gallery-view-container .swiper-pagination-bullet.active {
    width: 12px;
    height: 12px;
    background-color: white;
  }
}

@media screen and (max-width: 20cm) {
  .techtacticals-landing-page {
    height: 80vh;
  }
  .techtacticals-button-filled{
    display: initial;
  }
  .techtacticals-video video {
    height: 100%;
    object-fit: cover;
    margin-top: unset;
  }

  .video-overlay-content {
    padding: 20px 0px 0px 0px;
    top: 18%;
    align-items: center;
    height: 62%;
    flex-direction: column;
  }

  .video-overlay-content > p {
    font-size: 10vw;
    /* margin: 0 auto; */
  }

  /* .video-overlay-content > p > .second-line {
    margin-left: 16%;
  } */

  .video-overlay-content > p {
    text-align: center;
  }

  .techtacticals-description {
    width: 75%;
    padding: 0;
    border: none;
    margin-right: 0;
    margin-bottom: 11px;
    /* position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%); */
  }

  .techtacticals-video {
    height: 100%;
  }

  .techtacticals-description > p {
    font-size: 4vw;
    letter-spacing: 1px;
    padding: 10px 20px;
    text-align: center;
  }
  .techtacticals-button-filled{
    padding: 12px 42px;
    margin: 0px 25px;
    border-radius: 4px;
    font-size: 16px;
  }

  .gallery-view-container {
    width: 100%;
    height: 45vh;
    padding: 0px 20px;

    position: absolute;
    top: 56%;
    left: 50%;
    bottom: 80px;
    z-index: 999;
    transform: translate(-50%, -12%);

    border-radius: 25px;
  }

  .swiper-container {
    max-width: unset;
  }

  .swiper-slide {
    height: 35vh;
  }

  .swiper-pagination {
    bottom: 12%;
  }
}