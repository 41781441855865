.swiper-container {
  width: 100%;
  overflow-x: hidden;
  max-width: 800px;
  margin: 0 auto;

  border-radius: 25px;
}

.swiper-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.swiper-slide {
  flex: 0 0 100%;
  width: 100%;
  height: 360px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0;
}

.swiper-slide.active {
  opacity: 1;
}

.swiper-slide {
  border-radius: 25px;
}

.swiper-pagination {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: #cccccc79;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.swiper-pagination-bullet.active {
  background-color: white;
}

.swiper-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #0056b3;
}

.swiper-slide > img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

.swiper-btn-left, .swiper-btn-right {
  position: absolute;
  top: 45%;
  transform: translate(0, -50%);

  z-index: 9999;

  background-color: transparent;
  border: none;
  outline: none;

  border-radius: 50%;
}

.swiper-btn-left {
  left: -2%;
}

.swiper-btn-left .rotate-arrow {
  transform: rotate(180deg);
}

.swiper-btn-right {
  right: -3%;
}

@media screen and (min-width: 1000px) {
  .swiper-container {
    max-width: unset;
    height: inherit;

    margin-top: -20px;
  }

  .swiper-container .swiper-wrapper {
    height: inherit !important;
  }

  .swiper-container .swiper-wrapper .swiper-slide {
    height: inherit;
  }

  .swiper-container .swiper-pagination {
    bottom: -10px;
  }
}