@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;

  font-family: "Nunito";
}

/* Common Styles */
button:hover {
  cursor: pointer;
}

.techtacticals-button-filled {
  padding: 10px 12px;
  margin: 0px 27px;
  border-radius: 6px;

  font-size: 14px;
  color: white;
  background-color: #38C8AE;

  outline: none;
  border: none;
}

.techtacticals-button-outlined {
  padding: 10px 20px;
  margin: 0px 27px;
  border-radius: 6px;

  font-size: 14px;
  color: white;
  background-color: transparent;

  outline: none;
  border: 1px solid #38C8AE;
}


button:hover {
  opacity: 0.9;
}