.discover-our-projects {
  width: 100vw;
  height: 100vh;
  padding: 40px 112px 70px 112px;
  color: black;
  background-color: white;
  background-image: unset;
}

.discover-our-projects .title-and-heading {
  margin-bottom: unset !important;
}

.discover-our-projects .heading-and-desc {
  align-items: flex-start !important;
}

.discover-our-projects button {
  font-size: 1.2vw;
  margin-top: 2.5vh;
}

.discover-our-projects button img {
  width: 0.8vw;
  margin-left: 10px;
}

.product-cards-container {
  width: 100%;
  height: 100%;
  margin-top: 50px;

  display: flex;
  justify-self: space-between;
  align-items: flex-start;
}

.project-card {
  width: 30vw;
  height: 60vh;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.project-card img {
  width: 90%;
  height: 40vh;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 22px;
}

.project-card .title {
  font-size: 1.3vw;
  font-weight: bold;
  letter-spacing: 2.02px;

  margin-bottom: 10px;
}

.project-card .description {
  font-size: 14px;
  font-weight: 300;
}

.project-card.add-margin {
  margin: 0px 33px;
}

.discover-our-projects .content-box .heading-and-desc .title-and-heading .title {
  font-size: 1.2vw;
  font-weight: 600;
}

.discover-our-projects .content-box .heading-and-desc .title-and-heading .heading {
  font-weight: 700;
}

.discover-our-projects .description {
  font-size: 1.1vw;

  padding: unset;
  line-height: 24px;
  letter-spacing: 0.8px;

  background: unset;
}

.discover-our-projects .content-box .heading-and-desc {
  margin-bottom: 0px;
}

.discover-our-projects .project-description {
  font-size: 0.9vw;
  font-weight: 400;
}

@media screen and (min-width: 38cm) {
  .discover-our-projects .description {
    font-size: 1.35vw;

    line-height: 36px;
    letter-spacing: 0.8px;
  }

  .discover-our-projects button {
    margin-top: 2.2vh;
  }
}

@media screen and (max-width: 20cm) {
  .discover-our-projects {
    padding: 0px;
    height: 100%;

    padding-bottom: 2vh;
  }

  .discover-our-projects .content-box .heading-and-desc .title-and-heading .title {
    font-size: 3.2vw;
    font-weight: 700;
  }

  .techtacticals-services .content-box .heading-and-desc {
    height: 48vh;
  }

  .discover-our-projects .content-box .heading-and-desc .title-and-heading .heading {
    font-size: 6.8vw;
  }

  .discover-our-projects .description {
    padding: 0px 24px;
    font-size: 3.8vw;
    line-height: 27px;
  }

  .discover-our-projects button {
    font-size: 5vw;
  }

  .discover-our-projects button img {
    width: 3vw;
  }

  .product-cards-container {
    margin-top: unset;
    flex-direction: column;

    gap: 6vh;
  }

  .project-card {
    width: unset;
    height: unset;
  }

  .project-card img {
    width: 90%;
    margin: 0px auto 20px auto;
    height: 30vh;
  }

  .project-card.add-margin {
    margin: unset;
  }

  .project-card .title {
    font-size: 3.6vw;
    letter-spacing: 0.5px;
    margin: 0px 28px;
  }

  .discover-our-projects .project-description {
    font-size: 3.6vw;
    line-height: 20px;
    margin: 5px 28px;
  }
}