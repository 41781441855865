.workflow-image {
  width: 100%;
  height: 65vh;
}

.workflow-background-img {
  /* width: 100%;  */
  height: 72%;
  margin-top: 120px;
  background-image: url('../../../Assets/images/HowDoWeWorkIllustration.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}

.techtacticals-workflow {
  width: 100;
  padding: 115px;
  color: black;
  background-color: white;
  background-image: unset;
}

.techtacticals-workflow .title-and-heading {
  margin-bottom: unset !important;
}

.techtacticals-workflow .description {
  padding: unset;
  font-size: 1.1vw;

  line-height: 30px;
}

.techtacticals-workflow .heading-and-desc {
  align-items: flex-start !important;
}

.techtacticals-workflow {
  width: 100;
  padding: 115px;
  color: black;
  background-color: white;
  background-image: unset;
}

.techtacticals-workflow .title-and-heading {
  margin-bottom: unset !important;
}

.techtacticals-workflow .heading-and-desc {
  align-items: flex-start !important;
}

@media screen and (min-width: 38cm) {
  .techtacticals-workflow .description {
    font-size: 1.4vw;

    line-height: 3.8vh;
  }
}

@media screen and (max-width: 20cm) {
  .techtacticals-workflow {
    padding: 0px;
  }

  .workflow-image {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 48vh;
    justify-content: center;
  }

  .workflow-background-img {
    margin-top: 0px;
    display: flex;
    margin-left: 10px;
    width: 90%;
    height: 88%;
    align-items: center;
    justify-content: center;
    background-image: url('../../../Assets/images/HowDoWeWorkIllustrationMobile.svg');
  }

  .techtacticals-services .content-box .heading-and-desc .title-and-heading {
    margin: 40px 0px 20px 0px !important;
  }

  .techtacticals-services .content-box .heading-and-desc {
    height: 33vh;
  }

  .techtacticals-services .content-box .heading-and-desc .title-and-heading .title {
    font-size: 3.2vw;
  }

  .techtacticals-services .content-box .heading-and-desc .title-and-heading .heading {
    font-size: 6.8vw;
  }

  .techtacticals-workflow .description {
    font-size: 4.4vw;
    padding: 0px 20px;
  }

  .workflow-image img {
    width: 90%;
    margin: 0px 20px 0px 20px;
  }
}