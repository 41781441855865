.techtacticals-footer {
  width: 100vw;
  height: 80vh;
  padding: 120px 140px;

  background-image: url("/src/Assets/images/TechTacticalsBg.png");

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  color: white;
}

.lets-build-together {
  width: 100%;
  height: 50%;
  padding-bottom: 10vh;
  
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid #e5e5e55d;
}

.techtacticals-info {
  width: 100%;
  height: 50%;
  padding-top: 10vh;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lets-build-together p {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Montserrat";
}

.lets-build-together button {
  padding: 12px 64px;
  font-size: 1.1vw;
  letter-spacing: 2px;

  margin: 3% 0px 0px 0px;
}

.about-techtacticals {
  min-width: 20vw;
  width: 20vw;
  margin-right: 6vw;
}

.about-techtacticals img {
  width: 96%;
}

.techtacticals-links {
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.link-column {
  width: 12vw;
}

.link-column, .section-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.link-column:not(:last-child) {
  white-space: nowrap;
}

.techtacticals-links .column-heading {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: 26px;
}

.techtacticals-links .link {
  font-size: 16px;
  font-weight: 300;

  margin-bottom: 14px;
  color: #CCCCCC;
}

.techtacticals-links .link a {
  text-decoration: none;
  color: #CCCCCC;

  font-size: 1.2vw;
}

.techtacticals-footer .description {
  font-size: 1.1vw;
  color: #CCCCCC;

  margin-top: 20px;
}

.techtacticals-links .column-heading {
  font-size: 1.4vw;
}

@media screen and (min-width: 38cm) {
  .lets-build-together button {
    font-size: 1.6vw;
  }

  .about-techtacticals {
    width: 20vw;
    margin-right: unset;
  }

  .link-column {
    width: 12vw;
    margin: 0vh 1vw;
  }
}

@media screen and (max-width: 20cm) {
  .techtacticals-footer {
    padding: 0px;
    height: 88vh;
  }

  .lets-build-together {
    flex-direction: column;
    justify-content: center;
    padding-bottom: 5vh;
    height: 35%;
  }

  .lets-build-together p {
    font-size: 6.8vw;
    text-align: center;
    margin-bottom: 5%;
    margin-top: -8%;
  }

  .lets-build-together button {
    margin-bottom: -20%;
    font-size: 4.5vw;
    font-weight: 700;
  }

  .techtacticals-footer .description {
    font-size: 2.6vw;
  }

  .techtacticals-info {
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
  }

  .about-techtacticals {
    width: 70%;
    margin-bottom: 5vh;
    text-align: center;
  }

  .techtacticals-links {
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 0px 40px 0px 40px;
    gap: 10px;
  }

  .techtacticals-links .column-heading {
    min-width: 18vw;
    font-size: 3vw;
    white-space: nowrap;
    margin-bottom: 0px;
  }

  .link-column {
    width: unset;
    flex-direction: row;
  }

  .techtacticals-links .link a {
    font-size: 3vw;
  }

  .link-column:not(:last-child) {
    white-space: unset;
  }

  .techtacticals-links .link {
    font-size: 2.7vw;
    word-wrap: break-word;
  }

  .link-column, .section-links {
    flex-direction: row;
    gap: 20px;
  }

  .section-links br {
    display: none;
  }

  .section-links span p {
    min-width: 50vw;
  }

  .flex-column {
    flex-direction: column;
    gap: 0px;
  }
}