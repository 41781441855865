.techtacticals-workflow.reviews {
  padding: 100px 115px 60px 115px;
}

.techtacticals-workflow.reviews .description {
  margin-top: 30px;
}

.review-container {
  width: 100%;
  height: 100%;

  position: relative;
}

.review-container .swiper-container {
  max-width: unset;
  margin: unset;

  padding: 70px 0px 100px 0px;
}

.review-container .swiper-slide {
  width: calc(100%/3);
  padding: 20px;
}

.techtacticals-workflow.reviews {
  padding: 100px 115px 60px 115px;
}

.techtacticals-workflow.reviews .description {
  margin-top: 30px;
}

.review-container .swiper-container,
.review-container .swiper-wrapper,
.review-container .swiper-slide {
  height: inherit !important;
}

.review-card {
  width: 100%;
  height: 45vh;
  padding: 20px 20px 20px 24px;
  margin: 0px 25px;
  background-color: white;

  box-shadow: 0px 5px 20px #00000026;
  border-radius: 32px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.review-user {
  width: 100%;
  padding-top: 12px;
  margin-top: 20px;

  border-top: 1px solid #24303D45;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.review-set-cover {
  height: 100%;

  display: flex;
}

.user-image {
  width: 6vw;
  height: 6vw;
  object-fit: cover;
  border-radius: 50%;

  position: absolute;
  top: -4vh;
}

.review-card .review {
  font-size: 15px;
}

.review-card .name {
  font-size: 1.3vw;
  font-weight: 600;
}

.review-card .designation {
  font-size: 1vw;
  font-weight: 300;

  color: #9AA5B1;
}

.review-card .review-qoutes {
  margin-bottom: 7vh;
}

@media screen and (min-width: 38cm) {
  /* .user-image {
    width: 160px;
    height: 160px;
    top: -6vh;
  } */

  .review-card .review {
    font-size: 1.26vw;
  }

  .review-card .review-qoutes {
    width: 2vw;
  }
}

@media screen and (max-width: 20cm) {
  .techtacticals-workflow.reviews {
    height: 100%;
    padding: 0px;
  }

  .techtacticals-workflow.reviews .description {
    margin: 0px;
    line-height: 35px;
  }

  .user-image {
    width: 24vw;
    height: 24vw;
    top: -3vh;
    margin-left: 10px;
  }
  .review-container .swiper-container{
    padding: 70px 0px 81px 0px;
  }
  .review-card {
    border-radius: 20px;
    height: 40vh;
  }

  .review-card .review-qoutes {
    margin-bottom: 5vh;
  }

  .review-card .review {
    font-size: 3.6vw;
    line-height: 20px;
  }

  .review-card .name {
    font-size: 4vw;
  }

  .review-card .designation {
    font-size: 3.2vw;
  }

  .swiper-btn-left {
    left: 3%;
  }

  .swiper-btn-left .rotate-arrow, .swiper-btn-right img {
    width: 3vw;
  }

  .swiper-btn-right {
    right: 2.4%;
  }

  .swiper-pagination-bullet.active {
    background-color: rgba(128, 128, 128, 0.651);
  }
}