.navbar {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.666);
  backdrop-filter: blur(10px);

  color: white;
  text-decoration: none;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px 50px 20px 80px;

  position: sticky;
  top: 0px;
  z-index: 999999;
}

.navbar a {
  color: #9F9F9F;
  text-decoration: none;
}

.techtacticals-menubar {
  width: 40%;

  font-size: 1.2vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar a.link-active {
  color: white;
}

.link-underline {
  text-decoration: none;
  position: relative;
}

.link-underline::after {
  --underline-width: 85%;
  width: var(--underline-width);
  height: 2px;
  background-color: #38C8AE;

  content: "";
  border-radius: 2px;

  position: absolute;
  bottom: -2px;
  left: 0px;

  animation: extend-line 800ms cubic-bezier(0.19, 1, 0.22, 1) forwards;
}

@keyframes extend-line {
  from {
    width: 0%;
  } to {
    width: var(--underline-width);
  }
}

.techtacticals-logo {
  width: 12%;
}

.techtacticals-logo img {
  width: 100%;
}

.drawer-button {
  display: none;
}

.drawer-menu {
  visibility: hidden;
  position: absolute;
  z-index: -1;
}

@media screen and (max-width: 20cm) {
  .navbar {
    padding: 15px 20px;
  }

  .techtacticals-menubar a {
    display: none;
  }

  .techtacticals-support {
    display: none;
  }

  .techtacticals-logo {
    width: 40%;
    margin-top: 5px;
  }

  .drawer-button {
    display: block;
    background-color: transparent;
    border: none;
    outline: none;
  }

  .drawer-button img {
    width: 30px;
  }

  .drawer-menu {
    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0px;
    left: 0px;

    background: black;

    visibility: visible;
    z-index: unset;
  }

  .show-drawer {
    transition: cubic-bezier(0.19, 1, 0.22, 1) 600ms;
  }

  .remove-drawer {
    transform: translateX(100%);
    transition: cubic-bezier(0.19, 1, 0.22, 1) 600ms;
  }

  .techtacticals-drawer {
    width: 100%;
    height: 100%;
    padding: 14vh 30px 5vh 30px;
  }

  .drawer-links {
    display: flex;
    flex-direction: column;
    gap: 6vh;
  }

  .drawer-close-button {
    position: absolute;
    top: 5vh;
    right: 8vw;

    background-color: transparent;

    border: none;
    outline: none;

    border-radius: 50%;
    padding: 10px;
  }

  .drawer-close-button img {
    width: 20px;
    border-radius: 50%;
  }

  .navbar a {
    font-size: 4.5vw;
    letter-spacing: 0.8vw;
    font-weight: 600;
  }

  .drawer-support-buttons {
    position: absolute;
    bottom: 16vh;
    left: 50%;
    transform: translate(-50%, 0%);

    display: flex;
    flex-direction: column;
  }

  .drawer-support-buttons button {
    width: 80vw;
    padding: 1.4vh 0vw;
    margin: 20px 0px;
    white-space: nowrap;

    font-size: 3.6vw;
    font-weight: 800;
    letter-spacing: 2px;
  }

  .drawer-link-active {
    color: white !important;
    width: 40%;
    border-bottom: 2px solid #38C8AE;
    padding-bottom: 15px;
  }
}