.techtacticals-services {
  width: 100vw;
  padding: 54px 112px 60px 112px;

  background-image: url("/src/Assets/images/TechTacticalsBg.png");
  background-repeat: no-repeat;
  background-position: center;

  color: white;
}

.techtacticals-services .content-box {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.techtacticals-services .content-box .heading-and-desc {
  width: 100%;
  height: fit-content;
  margin-bottom: 3vh;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.techtacticals-services .content-box .heading-and-desc .title-and-heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 0px 180px 0px 0px;
  white-space: nowrap;
}

.techtacticals-services .content-box .heading-and-desc .title-and-heading .title {
  font-size: 1.7vw;
  font-weight: 600;
  letter-spacing: 2px;
  color: #24303D;
}

.techtacticals-services .content-box .heading-and-desc .title-and-heading .heading {
  font-size: 3vw;
  font-weight: 600;
  font-family: "Montserrat";
}

.techtacticals-services .description {
  padding: 22px 38px;
  background: rgba(255, 255, 255, 0.03);

  border-radius: 10px;

  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  font-size: 1.2vw;
  line-height: 3.6vh;
}

.services-offered-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.service-card {
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.service-card>img {
  width: 100%;

  border-radius: 20px;
}

.service-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 24.8vh;
  padding: 10px 10px 10px 10px;
  margin-top: 2vh;
  background: rgba( 255, 255, 255, 0.2 );
  backdrop-filter: blur( 60px );
  -webkit-backdrop-filter: blur( 60px );
  border-radius: 10px;
}

.services-margin {
  margin: 0px 120px;
}

.service-details>.name {
  min-height: 46px;
  font-size: 1.8vw;
  font-weight: 600;
  margin-bottom: 12px;
  line-height: 28px;    
  letter-spacing: 1px;
}

.service-details .service-desc {
  font-size: 1vw;
  font-weight: 300;
  letter-spacing: 1px;
  margin-bottom: 3vh;
}

.service-details button {
  width: 50%;
  margin: 0;
  padding: 10px 16px 10px 12px;
  border-radius: 2px;

  border-radius: 6px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.service-details button:hover {
  width: 100%;
  box-shadow: 0px 0px 20px #38C8AE8A;
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.service-details button img {
  width: 13px;
  margin: 2px 0px 0px 4px;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.service-details button:hover img {
  width: 13px;
  margin: 2px 0px 0px 12px;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 1s;
}

.techtacticals-services .content-box .heading-and-desc .title-and-heading .title.services {
  color: #D2D2D2;
  font-weight: 600;
}

@media screen and (min-width: 38cm) {
  .techtacticals-services .description {
    width: 50%;
    font-size: 1vw;
    line-height: 4vh;
  }

  .service-details>.name {
    font-size: 1.2vw;
  }

  .service-details .service-desc {
    font-size: 1vw;

    margin-bottom: 3vh;
  }

  .service-details button {
    font-size: 1.1vw;
    padding: 10px 30px;
  }

  .service-details {
    height: 25vh;
  }
}

@media screen and (max-width: 20cm) {
  .techtacticals-services {
    padding: 0px 0px 40px 0px;
    width: 100%;
    height: 100%;
    background-size: cover;
  }

  .techtacticals-services .content-box .heading-and-desc {
    flex-direction: column;
  }

  .techtacticals-services .description {
    border-radius: 0px;
    font-size: 16px;
    line-height: 3.8vh;
    margin-bottom: 50px;
    background: none;
    backdrop-filter: initial;
    -webkit-backdrop-filter: none;
    padding: 22px 20px;
  }

  /* .techtacticals-services .description {
    padding: 22px 38px;
    
    border-radius: 10px;
    
    background: rgba( 255, 255, 255, 0.03 );
    backdrop-filter: blur( 20px );
    -webkit-backdrop-filter: blur( 20px );
  
    font-size: 1.2vw;
    line-height: 3.6vh;
  } */

  .techtacticals-services .content-box .heading-and-desc .title-and-heading {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .techtacticals-services .content-box .heading-and-desc{
    height: 50vh;
  }
  .techtacticals-services .content-box .heading-and-desc .title-and-heading .title.services {
    font-size: 3.2vw;
  }

  .techtacticals-services .content-box .heading-and-desc .title-and-heading .heading {
    font-size: 6.8vw;
  }

  .services-offered-container {
    flex-direction: column;
    padding: 0px 40px;
  }

  .services-margin {
    margin: 25% 0;
  }

  .service-details {
    height: unset;
  }

  .service-details button {
    position: unset;
    width: 100%;
  }

  .service-details>.name {
    font-size: 8.2vw;
    line-height: 5vh;
  }

  .service-details .service-desc {
    font-size: 4.5vw;
    margin: 0% 0 5% 0;
    letter-spacing: 1px;
  }

  .service-details {
    margin: 14% 0px 0% 0px;
    padding: 20px 25px 23px 25px;
  }
}